import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { IoWarningOutline } from "react-icons/io5";
import { ReactElement } from "react";
import "./DeleteTaskModal.css";
import { deleteTask } from "../../../Api/deleteTaskApi";
import toast from "react-hot-toast";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { deleteSingleTask } from "../../../redux/taskListSlice";
import axios from "axios";

interface DeleteTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  taskDetails: {
    taskId: string;
    taskName: string;
  };
  fetchTask:()=>void;
  pageSize:number;
  currentPage:number;
  setCurrentPage:React.Dispatch<React.SetStateAction<number>>
}

const DeleteTaskModal: React.FC<DeleteTaskModalProps> = ({
  isOpen,
  onClose,
  taskDetails,
  fetchTask,
  pageSize,
  currentPage,
  setCurrentPage
}): ReactElement => {
  const dispatch = useAppDispatch();
  const taskList = useAppSelector((state)=>state.taskList.taskList);

  // delete the task
  const handleDetleteTask = async () => {
    try {
      const response = await deleteTask(taskDetails.taskId);
      dispatch(deleteSingleTask(taskDetails.taskId));


      if(taskList.length===1 && currentPage!==1){
         setCurrentPage(currentPage-1);
      }
      else{
        fetchTask()
      }
      /*
       * 
       * if pageSize >1 and current Page === 1 fetch tasklist again
       * if pageSize 1 and currentpage ===1 fetch tasklist again 
       * if page Size > 1 and currentPage !== 1 fetch tasklist again
       * if page size 1 currentpage !==1 setCurrentPage(currentPage-1)
       */
      // fetchTask()
      toast.success(" Task delete successfully");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error?.response?.data?.message || "Something went wrong");
      }
    }
    onClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={() => onClose} isCentered>
        <ModalOverlay />
        <ModalContent className="delete-modal">
          <ModalBody className="delete-modal-body">
            <div>
              <IoWarningOutline className="delete-warning-icon" size={40} />
            </div>
            <div className="delete-confirmation-text ">
              <p>
                Are you sure you want <br />
                to delete the task - {taskDetails.taskName}
              </p>
            </div>
          </ModalBody>
          <ModalFooter className="delete-modal-buttons">
            <Button
              className="delete-task-modal-cancel-button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="delete-task-modal-delete-button"
              onClick={() => {
                handleDetleteTask();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DeleteTaskModal;
