import axios from "axios";
import cookies from "react-cookies"
// http://localhost:8000

const axiosInstance = axios.create({
    baseURL:process.env.REACT_APP_SERVER_URL,
    headers: {
          "Content-Type": "application/json", // Set correct header for file uploads
      },
})

export default axiosInstance





axiosInstance.interceptors.request.use((config)=>{
    const accessToken = cookies.load("access-token");
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config
})