import { configureStore,  } from "@reduxjs/toolkit";
import taskListReducer from "./taskListSlice";
import taskResultReducer from "./taskResultSlice";
import userDetailsReducer from "./userDetailsSlice";

export const store = configureStore({
  reducer: {
    taskList: taskListReducer,
    result: taskResultReducer,
    userDetails: userDetailsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
