import "jspdf-autotable";
import { DeviceDetails, ResultDetails } from "./interfaces";
import { GridRowId } from "@mui/x-data-grid";

export const trimFileName = (str: string, ext: "csv" | "zip"): string => {
  if (str.length > 15) {
    str = str.slice(0, 15);
    str = str.concat(`...${ext}`);
  }
  return str;
};

export const checkFileExtension = (
  fileName: string,
  field: string
): boolean => {
  const splitedFileName = fileName.split(".");
  const fileExt = splitedFileName[splitedFileName.length - 1];

  if (field === "advik") {
    return fileExt === "csv";
  } else {
    return fileExt === "zip";
  }
};


export const downloadCSV = (resultData: ResultDetails,deviceData :DeviceDetails,rowId:GridRowId) => {

  
  const csvRows = [];
  const header = [];

 for(const key in deviceData){
     header.push(`"${key}"`);
     const value =String(deviceData[key as keyof DeviceDetails])
     csvRows.push(`"${value}"`);
 }

  for (const key in resultData) {
    if(key ==="id")continue;
    else  {
      header.push(`"${key}"`);
      const value =String(resultData[key as keyof ResultDetails])
      csvRows.push(`"${value}"`);
    }
  }
  const csvString = `${header.join(",")} \n ${csvRows.join(",")}`;

  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `row${rowId}_${deviceData.deviceId}`;
  a.click();
  URL.revokeObjectURL(url);
};

