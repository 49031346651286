import { ReactElement } from "react";
import "./UserDashBoardPage.css";
import Navbar from "../../components/Navbar/Navbar";
import { Outlet } from "react-router-dom";

const UserDashBoardPage: React.FC = (): ReactElement => {
  return (
    <div className="user-dashboard">
      <Navbar />
      <Outlet />
    </div>
  );
};

export default UserDashBoardPage;
