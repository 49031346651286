import toast from "react-hot-toast";
import { AddTaskFormInterface } from "./AddTaskModal";
import { addTaskAxios } from "../../../Api/addTaskApi";
import axios from "axios";
import { toastMessage } from "../../../toastMessage";
export const handleAddTaskSubmit = async (
  dataValues: AddTaskFormInterface,
  setLoading: (loading: boolean) => void,
  onClose: () => void
) => {


  for (const key in dataValues) {
    if (
      dataValues[key as keyof AddTaskFormInterface] === "" ||
      dataValues[key as keyof AddTaskFormInterface] === null
    ) {
      toast.error("Please Fill all fields");
      return;
    }
  }

  const formData = new FormData();
  formData.append("name", dataValues.name);
  formData.append("details", dataValues.details);
  dataValues.advik && formData.append("advik", dataValues.advik); // fileInputAdvik is your file input for Advik
  dataValues.telco && formData.append("telco", dataValues.telco); // fileInputTelco is your file input for Telco

  try {
    setLoading(true);
    const response = await addTaskAxios(formData);
    toast.success("Task Submit Successfully");
    return response
   
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const statusCode = error.response?.status
      toast.error(toastMessage[statusCode||500])
    }
  } finally {
    setLoading(false);
    onClose();
  }
};
