import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoWarningOutline } from "react-icons/io5";
import cookies from 'react-cookies'
import { useNavigate } from "react-router-dom";
import "./LogoutModal.css"
import { clearResult } from "../../../redux/taskResultSlice";
import { useAppDispatch } from "../../../hook";

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({
  isOpen,
  onClose,
}): ReactElement => {

   const navigate = useNavigate();

   const dispatch = useAppDispatch();
  const handleLogout = () => {
    cookies.remove("access-token");
    dispatch(clearResult())
    navigate("/login");
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent className="logout-modal">
          <ModalBody className="logout-modal-body">
            <div>
              <IoWarningOutline className="logout-warning-icon" size={40} />
            </div>
            <div className="logout-confirmation-text ">
              <p>
                Are you sure you want <br />
                to Logout
              </p>
            </div>
          </ModalBody>
          <ModalFooter className="logout-modal-buttons">
            <Button
              className="logout-task-modal-cancel-button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              className="logout-task-modal-logout-button"
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default LogoutModal;
