import { createSlice } from "@reduxjs/toolkit";
import { taskItem } from "../interfaces";
import { PayloadAction } from "@reduxjs/toolkit";
interface taskList {
  taskList: Array<taskItem>;
  totalTasks: number;
}
const initialState: taskList = {
  taskList: [],
  totalTasks: 0,
};
const taskListSlice = createSlice({
  name: "taskList",
  initialState,
  reducers: {
    addTaskList: (state, action: PayloadAction<taskList>) => {
      state.taskList = action.payload.taskList;
      state.totalTasks = action.payload.totalTasks;
    },
    addSingleTask: (
      state,
      action: PayloadAction<{ task: taskItem; pageSize: number }>
    ) => {
      state.taskList = [action.payload.task, ...state.taskList];
      state.totalTasks = state.totalTasks + 1;
      if (state.totalTasks > action.payload.pageSize) {
        state.taskList.pop();
      }
    },
    deleteSingleTask: (state, action: PayloadAction<string>) => {
      state.taskList = state.taskList.filter(
        (task) => task.id !== action.payload
      );
      state.totalTasks = state.totalTasks - 1;
    },
    changeStatus: (
      state,
      action: PayloadAction<{ taskId: string; status: string;message:string }>
    ) => {
      state.taskList = state.taskList.map((task) =>
        task.id === action.payload.taskId
          ? { ...task, status: action.payload.status,message:action.payload.message }
          : task
      );
    },
  },
});

export const { addTaskList, addSingleTask, deleteSingleTask, changeStatus } =
  taskListSlice.actions;
export default taskListSlice.reducer;
