import { ReactElement, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hook";
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { TaskListHeader } from "../../constants";
import { FaRegTrashAlt } from "react-icons/fa";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Tippy from "@tippyjs/react";
import { fetchStatus } from "../../Api/fetchStatusApi";
import { changeStatus } from "../../redux/taskListSlice";
import axios from "axios";
import { toastMessage } from "../../toastMessage";
import toast from "react-hot-toast";
import { IoMdRefreshCircle } from "react-icons/io";
import { Skeleton } from "@mui/material";
import { Center } from "@chakra-ui/react";

interface TasklistTableProps {
  handleViewClick: (taskId: string, taskName: string) => void;
  onOpen: () => void;
  setDeleteTaskDetails: React.Dispatch<
    React.SetStateAction<{
      taskId: string;
      taskName: string;
    }>
  >;
  currentPage: number;
  pageSize: number;
}

/**
 *  take the table data from store
 * make a row data with id
 * and column with id and view button
 */
const TasklistTable: React.FC<TasklistTableProps> = ({
  handleViewClick,
  onOpen,
  setDeleteTaskDetails,
  currentPage,
  pageSize,
}): ReactElement => {
  const taskList = useAppSelector((state) => state.taskList.taskList);

  const [statusLoading, setStatusLoading] = useState(false);

  const dispatch = useAppDispatch();
  const theme = createTheme({
    palette: {
      mode: "light", // Ensure 'light' or 'dark' is set correctly
    },
  });

  // api for get status of task
  const handleGetStatus = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
   e.stopPropagation()
    const requestedTaskIds: Array<string> = [];
    taskList.map((task) => requestedTaskIds.push(task.id));
    setStatusLoading(true);
    try {
      const response = await fetchStatus(requestedTaskIds);
      response.map(
        (task: { taskId: string; status: string; message: string }) =>
          task.status === "errored"
            ? dispatch(
                changeStatus({
                  taskId: task.taskId,
                  status: "failed",
                  message: task?.message || "Something went wrong",
                })
              )
            : dispatch(changeStatus(task))
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const statusCode = error?.response?.status;
        toast.error(toastMessage[statusCode || 500]);
      }
    } finally {
      setStatusLoading(false);
    }
  };

  const tasklistRow = taskList.map((row, index) => ({
    ...row,
    sno: (currentPage - 1) * pageSize + index + 1,
  }));

  const tasklistHeader: GridColDef[] = [
    ...TaskListHeader.map((header) => {
      return {
        field: header.field,
        width: header.width,
        headerName: header.field.toUpperCase(),
        headerClassName: `${header.field}`,
        renderHeader: (params: GridColumnHeaderParams) => {
          if (header.field === "status")
            return (
              <div className="task-status-header">
                <span>{header.field.toUpperCase()}</span>
                <button
                  className="mini-button"
                  onClick={handleGetStatus}
                  disabled={statusLoading}
                >
                  <IoMdRefreshCircle />
                </button>
              </div>
            );
        },
        renderCell: (params: GridRenderCellParams) => {
          let status = params.row?.status;
          status = status.charAt(0).toUpperCase() + status.slice(1);
          if (header.field === "status") {
            return (
              <div className="task-status-container">
                {statusLoading ? (
                  <Skeleton width={150} />
                ) : (
                  <Tippy
                    content={params.row.message}
                    placement="bottom"
                    theme="custom"
                  >
                    <span className={`task-status-${status}-text`}>
                      {status}
                    </span>
                  </Tippy>
                )}
              </div>
            );
          }
        },
      };
    }),
    ...[
      {
        field: "view",
        headerName: "VIEW",
        headerClassName: "view",
        width: 200,
        renderCell: (params: GridRenderCellParams) => {
          const task = params.row;
          return (
            <div className="action-div">
              <button
                className="task-view-button"
                disabled={task?.status !== "complete"}
                onClick={() => {
                  handleViewClick(task.id, task.name);
                }}
              >
                View
              </button>
              <button
                className="task-delete-button"
                disabled={
                  !(task?.status === "complete" || task?.status === "failed")
                }
                onClick={() => {
                  onOpen();
                  setDeleteTaskDetails({
                    taskId: task.id,
                    taskName: task.name,
                  });
                }}
              >
                <FaRegTrashAlt size={18} />
              </button>
            </div>
          );
        },
      },
    ],
  ];

  return (
    <div>
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={tasklistRow}
          columns={tasklistHeader}
          rowHeight={46}
          columnHeaderHeight={55}
          hideFooter
          columnVisibilityModel={{ message: false }}
          disableColumnMenu={true}
          
          sx={{
            minHeight: "515px",
          
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default TasklistTable;
