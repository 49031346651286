import { ReactElement, useState } from "react";
import "./LoginForm.css";
import Loader from "../Loader/Loader";
import { login } from "../../Api/loginApi";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hook";
import { addUserDetails } from "../../redux/userDetailsSlice";
import axios from "axios";
import toast from "react-hot-toast";
import { toastMessage } from "../../toastMessage";
import { getUserDetails } from "../../Api/getUserDetailsApi";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export interface formDataInterface {
  email: string;
  password: string;
}

const LoginForm: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<formDataInterface>({
    email: "",
    password: "",
  });

  const [isError, setIsError] = useState<boolean>(true);
  const [error, setError] = useState<formDataInterface>({
    email: "",
    password: "",
  });

  const [isShowPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  //function  for validation check of the password and email
  const checkValidation = (data: formDataInterface, field: string) => {
    switch (field) {
      case "email":
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const emailMatch = emailRegex.test(data.email);
        if (!emailMatch) {
          setError((prevState) => ({
            ...prevState,
            email: "*Not a valid email id",
          }));
          setIsError(true);
        } else {
          setError((prevState) => ({
            ...prevState,
            email: "",
          }));
          setIsError(false);
        }
        break;
      case "password":
        if (data.password.length < 8) {
          setError((prevState) => ({
            ...prevState,
            password: "*Password's minimum length is 8",
          }));
          setIsError(true);
        } else {
          setError((prevState) => ({
            ...prevState,
            password: "",
          }));
          setIsError(false);
        }
        break;
      default:
        break;
    }
  };

  const handleOnChange = (name: string, value: string) => {
    let formdata = {
      ...formData,
      [name]: value,
    };
    switch (name) {
      case "email":
        setFormData(formdata);
        checkValidation(formdata, name);
        break;
      case "password":
        setFormData(formdata);
        checkValidation(formdata, name);
        break;
      default:
        return;
    }
  };

  // function to handle submit the form
  const handleFormSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    // if (!checkValidation(formData)) return;
    try {
      setLoading(true);
      const response = await login(formData);
      if (response) {
        try {
          const userDetails = await getUserDetails();
          if (userDetails) {
            dispatch(
              addUserDetails({
                email: userDetails.email,
                name: userDetails.username,
              })
            );
          }
        } catch (error) {
          navigate("/login");
        }
        navigate("/");
        setFormData({ email: "", password: "" });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const statusCode = error?.response?.status;
        toast.error(toastMessage[statusCode || 500]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-form-container">
      <div className="login-form-container-text">
        <h1>Log In</h1>
        <span>
          Welcome Back! <br />
          Please enter your details
        </span>
      </div>
      <form>
        <div className="email-field">
          <label>Email</label>
          <input
            name="email"
            type="email"
            value={formData.email}
            placeholder="example@gmail.com"
            onChange={(e) =>
              handleOnChange(e.currentTarget.name, e.currentTarget.value)
            }
          />
          {error.email !== "" && <span className="error">{error.email}</span>}
        </div>
        <div className="password-field">
          <label>Password</label>

          <input
            type={isShowPassword ? "text" : "password"}
            name="password"
            placeholder="enter a password"
            value={formData.password}
            onChange={(e) =>
              handleOnChange(e.currentTarget.name, e.currentTarget.value)
            }
          />
          <button
            type="button"
            className="eye-toggler"
            onClick={() => setShowPassword(!isShowPassword)}
          >
            {isShowPassword ? <FaEye /> : <FaEyeSlash />}
          </button>
          {error.password !== "" && (
            <span className="error">{error.password}</span>
          )}
        </div>

        <div>
          <button
            type="submit"
            className="login-button "
            onClick={(e) => handleFormSubmit(e)}
            disabled={loading || isError}
          >
            {loading ? <Loader/>: "Log in"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
