import { FETCH_TASK } from "../ApiUrl"
import axiosInstance from "../axiosInstance"

export const fetchAllTask = async(pagesize:number,currentPage:number)=>{
    try {
        const response = await axiosInstance.get(FETCH_TASK+"?pageSize="+pagesize+"&page="+currentPage);
        return response.data;

    } catch (error) {
         throw  error;
    }
}