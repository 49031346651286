import axiosInstance from "../axiosInstance";
import { ADD_TASK,  } from "../ApiUrl";



export const addTaskAxios = async (formData: FormData) => {
  try {
    const response = await axiosInstance.post(ADD_TASK, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

