import { FETCH_ALL_STATUS } from "../ApiUrl"
import axiosInstance from "../axiosInstance"

export const fetchStatus=async(requestedTaskIds:Array<string>)=>{
     try {
        const response = await axiosInstance.post(FETCH_ALL_STATUS,{requestedTaskIds:requestedTaskIds});
        return response.data
     } catch (error) {
         throw error;
     }
}