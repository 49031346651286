import { createSlice } from "@reduxjs/toolkit";
 import { PayloadAction } from "@reduxjs/toolkit";
import { DeviceDetails, ResultDetails } from "../interfaces";
 const initialState={
    taskDetails:{
      taskName:"",
      taskId:""
    },
    result:[] as ResultDetails[],
    deviceDetails:[] as DeviceDetails[]
 }

const taskResultSlice = createSlice({
    name:"taskResult",
    initialState,
    reducers:{
      addResult :(state,action:PayloadAction<Array<ResultDetails>>)=>{
        
         state.result = action.payload;
      },
      addDeviceDetails:(state,action:PayloadAction<Array<DeviceDetails>>)=>{
          state.deviceDetails= action.payload;
      },
      clearResult:(state)=>{
         state.result=initialState.result
      },
      addTaskDetails:(state,action:PayloadAction<{taskName:string,taskId:string}>)=>{
         state.taskDetails = action.payload;
      }
    }
})

export const {addResult,addTaskDetails,addDeviceDetails,clearResult} = taskResultSlice.actions;
export default taskResultSlice.reducer;