import LoginPage from "./pages/LoginPage/LoginPage";
import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import UserDashBoardPage from "./pages/UserDashBoardPage/UserDashBoardPage";
import { ChakraProvider } from "@chakra-ui/react";
import FileShownPage from "./pages/FileShownPage/FileShownPage";
import TaskListContainer from "./components/TaskListContainer/TaskListContainer";
import { useEffect, useState } from "react";
import { getUserDetails } from "./Api/getUserDetailsApi";
import axios from "axios";
import cookies from "react-cookies";
import { useAppDispatch } from "./hook";
import { addUserDetails } from "./redux/userDetailsSlice";



function App() {
  const [loading, setLoading] = useState(true);
   const [cookie ,setCookie ] = useState(cookies.load("access-token"));
   const dispatch = useAppDispatch();
   
   const setUserDetails = async () => {  
    try {
       if(cookie){
       const response = await getUserDetails();
       dispatch(addUserDetails({email:response.email,name:response.username}));
       }
       else{
        if( window.location.pathname!=="/login"){
        //  toast.error("Unauthorized Access");
         window.location.href="/login"
        }
       }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        cookies.remove("access-token");
        const statusCode = error?.response?.status;
        // toast.error(toastMessage[statusCode || 500]);
      }
         window.location.href="/login"
    } finally {
      setLoading(false);
    }
  };

  console.log(process.env.REACT_APP_SERVER_URL)
  useEffect(() => {
    setUserDetails();
  }, [cookie]);

  return (
    <Router>
      <ChakraProvider>
        <div className="App">
          {loading ? (
            <div className="loading">Loading ... </div>
          ) : (
            <Routes>
              <Route path="/" element={<UserDashBoardPage />}>
                <Route index element={<TaskListContainer />} />
                <Route path="/result/:taskId/" element={<FileShownPage />} />
              </Route>

              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          )}
        </div>
      </ChakraProvider>
    </Router>
  );
}

export default App;
