import { ReactElement, useState } from "react";
import FileDownloaderContainer from "../../components/FileDownLoadContainer/FileDownloadContainer";
import "./FileShownPage.css";
import FileData from "../../components/FileData/FileData";

const FileShownPage: React.FC = (): ReactElement => {
  const [resultLoading, setResultLoading] = useState(true);
  const [resultColumnWidth, setResultColumnWidth] = useState<
    { field: string; width: number }[]
  >([]);

  const [deviceColumnWidth, setDeviceColumnWidth] = useState<
    { field: string; width: number }[]
  >([]);

  return (
    <div className="file-shown-page">
      <FileDownloaderContainer resultLoading={resultLoading} />
      <FileData
        resultLoading={resultLoading}
        setResultLoading={setResultLoading}
        resultColumnWidth={resultColumnWidth}
        setResultColumnWidth={setResultColumnWidth}
        deviceColumnWidth={deviceColumnWidth}
        setDeviceColumnWidth={setDeviceColumnWidth}
      />
    </div>
  );
};

export default FileShownPage;
