export const TaskStatus = ["complete", "failed", "in-progress", "pending"];

export const TaskListHeader = [{field:"sno",width:80},{field:"date",width:200},{field:"name",width:250}, {field:"details",width:450}, {field:"status",width:200},{field:"message"}];
export const DeviceDetailsHeader = [
  "deviceId",
  "ipAddress",
  "reqTime",
  "location",
  "city",
  "carrier",
  "appBundle",
  "make",
  "model",
  "os",
  "osv",
  "connectionType",
  "geoType",
];

export const ResultHeader = [
  "MSISDN",
  "IMEI",
  "IMSI",
  "Downlink_Vol",
  "Uplink_Vol",
  "Session_Start_Time",
  "Session_End_Time",
  "Home_Circle",
  "Public_IPv4",
  "Destination_IP4",
  "Duration",
  "Access_Point_Name",
  "2g/4g/5g",
  "CGI Latitude",
  "CGI Longitude",
  "make",
  "model",
];
