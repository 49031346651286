// upload-and-process/  - route for uploading and process the form data
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  CloseButton,
} from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import "./AddTaskModal.css";
import { Formik } from "formik";
import { useDropzone } from "react-dropzone";
import { MdFolderZip, MdOutlineDriveFolderUpload } from "react-icons/md";
import Loader from "../../Loader/Loader";
import { handleAddTaskSubmit } from "./handleSubmit";
import toast from "react-hot-toast";
import { FaFileCsv } from "react-icons/fa";
import { checkFileExtension, trimFileName } from "../../../helperFunctions";
import { taskItem } from "../../../interfaces";
import { addSingleTask } from "../../../redux/taskListSlice";
import { useAppDispatch, } from "../../../hook";
import { format } from "date-fns";

interface AddTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchTask: () => void;
  pageSize: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export interface AddTaskFormInterface {
  name: string;
  details: string;
  advik: File | null;
  telco: File | null;
}

const AddTaskModal: React.FC<AddTaskModalProps> = ({
  isOpen,
  onClose,
  fetchTask,
  pageSize,
  currentPage,
  setCurrentPage,
}): ReactElement => {
  const [dataValues, setDataValues] = useState<AddTaskFormInterface>({
    name: "",
    details: "",
    advik: null,
    telco: null,
  });
  const dispatch = useAppDispatch();
  // const taskList = useAppSelector((state) => state.taskList.taskList);
  const [loading, setLoading] = useState(false);
  const handleDropAdvikFile = (files: File[]) => {
    const file = files?.[0];
    setDataValues({ ...dataValues, advik: file });
  };
  const handleDropTelcomFile = (files: File[]) => {
    const file = files?.[0];
    setDataValues({ ...dataValues, telco: file });
  };

  //remove the file
  const removeFile = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    name: string
  ) => {
    e.stopPropagation();
    const dataClone = { ...dataValues };
    name === "advik"
      ? (dataClone.advik = null)
      : name === "telco" && (dataClone.telco = null);
    setDataValues(dataClone);
  };

  // when the value of input name and details change
  const onDataChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;
    setDataValues({ ...dataValues, [field]: value });
  };

  // for handle the file change
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0]; // Get the first file
    const field = e.currentTarget.name;

    if (file?.name && checkFileExtension(file?.name, field)) {
      setDataValues({ ...dataValues, [field]: file }); // Store File object directly
    } else {
      toast.error("Invalid file type");
    }
  };

  // for dragging and ddroping a file
  const {
    getRootProps: getRootPropsAdvik,
    getInputProps: getInputPropsAdvik,
    isDragActive: isDragActiveAdvik,
  } = useDropzone({
    onDrop: handleDropAdvikFile,
    accept: {
      "text/csv": [
        ".csv",
      ],
    },
    onDropRejected: () => {
      toast.error("Invalid file type");
    },
  });
  const {
    getRootProps: getRootPropsTelecom,
    getInputProps: getInputPropsTelecom,
    isDragActive: isDragActiveTelecom,
  } = useDropzone({
    onDrop: handleDropTelcomFile,
    accept: { "application/zip": [".zip"] },
    onDropRejected: () => {
      toast.error("Invalid file type");
    },
  });

  const clearDataValues = () => {
    setDataValues({ name: "", details: "", advik: null, telco: null });
  };

  const isSubmitDisabled = () => {
    for (const key in dataValues) {
      if (
        dataValues[key as keyof AddTaskFormInterface] === "" ||
        dataValues[key as keyof AddTaskFormInterface] === null
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = async () => {
    const response = await handleAddTaskSubmit(dataValues, setLoading, onClose);
    if (response) {
      const task: taskItem = {
        id: response?._id,
        name: dataValues.name,
        details: dataValues.details,
        date: format(Date.now(), "dd-MM-yyyy"),
        status: "pending",
        message:"Task is in queue"
      };
      if (currentPage === 1) {
        fetchTask();
      } else {
        setCurrentPage(1);
      }
      // dispatch(addSingleTask({ task: task, pageSize: pageSize }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <Formik
        initialValues={dataValues}
        onSubmit={() => {
          handleSubmit();
          clearDataValues();
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContent className="add-task-modal">
              <ModalCloseButton isDisabled={loading} />
              <ModalHeader className="add-task-header">
                Fill Details
              </ModalHeader>
              <ModalBody className="add-task-form">
                <FormControl className="">
                  <FormLabel className="add-task-labels" htmlFor="name">
                    Name <span className="required-asterik">*</span>
                  </FormLabel>
                  <Input
                    name="name"
                    placeholder="Enter the name of the task"
                    className="add-task-input"
                    isDisabled={loading}
                    onChange={(e) => onDataChange(e)}
                    required
                    minLength={5}
                    maxLength={50}
                    value={dataValues?.name}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel className="add-task-labels" htmlFor="details">
                    Details <span className="required-asterik">*</span>
                  </FormLabel>
                  <Textarea
                    id="details"
                    name="details"
                    className="add-task-input"
                    placeholder="Write the details of the task here"
                    rows={4}
                    isDisabled={loading}
                    onChange={(e) => onDataChange(e)}
                    required
                    minLength={5}
                    maxLength={150}
                    value={dataValues?.details}
                  />
                </FormControl>
                <div className="file-inputs">
                  <div className="advik-file-field">
                    <FormLabel
                      className="add-task-file-labels"
                      htmlFor="advikFile"
                    >
                      Advik <span className="required-asterik">*</span>
                    </FormLabel>
                    <div
                      {...getRootPropsAdvik()}
                      className={`advik-file-input${
                        loading ? "-isDisabled" : ""
                      }`}
                    >
                      <input
                        id="advikFile"
                        name="advik"
                        {...getInputPropsAdvik()}
                        disabled={loading}
                        onChange={(e) => onFileChange(e)}
                        type="file"
                        accept=".csv"
                      />
                      <div className="advik-file-input-inner-container">
                        <div className="file-upload-icon">
                          {dataValues.advik ? (
                            <FaFileCsv color="grey" size={30} />
                          ) : (
                            <MdOutlineDriveFolderUpload size={30} />
                          )}
                        </div>
                        <div className="file-container">
                          <p
                            className={`${
                              dataValues.advik
                                ? "file-name"
                                : "file-placeholder"
                            }`}
                          >
                            {dataValues?.advik
                              ? trimFileName(dataValues?.advik.name, "csv")
                              : isDragActiveAdvik
                              ? "Drop the files here ..."
                              : "Drag or click to upload"}
                            {!dataValues.advik && (
                              <span
                                className="accepted-file-hint"
                                style={{ color: "red" }}
                              >
                                {" "}
                                Accepted files : .csv
                              </span>
                            )}
                          </p>
                          {dataValues.advik && (
                            <span>
                              <CloseButton
                                size="sm"
                                onClick={(e) => removeFile(e, "advik")}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="telecom-file-field">
                    <FormLabel
                      className="add-task-file-labels"
                      htmlFor="telecomFile"
                    >
                      Telecom <span className="required-asterik">*</span>
                    </FormLabel>
                    <div
                      {...getRootPropsTelecom()}
                      className={`telecom-file-input${
                        loading ? "-isDisabled" : ""
                      } `}
                    >
                      <input
                        id="telcoFile"
                        name="telco"
                        {...getInputPropsTelecom()}
                        disabled={loading}
                        onChange={(e) => onFileChange(e)}
                        type="file"
                        accept=".zip"
                      />
                      <div className="telecom-file-input-inner-container">
                        <div className="file-upload-icon">
                          {dataValues.telco ? (
                            <MdFolderZip size={30} color="grey" />
                          ) : (
                            <MdOutlineDriveFolderUpload size={30} />
                          )}
                        </div>
                        <div className="file-container">
                          <p
                            className={`${
                              dataValues.telco
                                ? "file-name"
                                : "file-placeholder"
                            }`}
                          >
                            {dataValues?.telco
                              ? trimFileName(dataValues?.telco.name, "zip")
                              : isDragActiveTelecom
                              ? "Drop the files here ..."
                              : "Drag or click to upload "}
                            {!dataValues.telco && (
                              <span
                                className="accepted-file-hint"
                                style={{ color: "red" }}
                              >
                                {" "}
                                Accepted files : .zip
                              </span>
                            )}
                          </p>
                          {dataValues?.telco && (
                            <span>
                              <CloseButton
                                onClick={(e) => removeFile(e, "telco")}
                                size="sm"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="add-task-modal-footer">
                <Button
                  className="submit-add-task-form"
                  type="submit"
                  isDisabled={isSubmitDisabled() || loading}
                >
                  Submit
                </Button>
                {/* <Toaster /> */}
              </ModalFooter>
              {loading && <Loader width={40} height={40} />}
            </ModalContent>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTaskModal;
