import "./LoginPage.css";
import { ReactElement } from "react";
import LogoOnLogin from "../../components/LogoOnLogin/LogoOnLogin";
import LoginForm from "../../components/LoginForm/LoginForm";
import logo from "../../logo/data_bridge.svg";

const LoginPage: React.FC = (): ReactElement => {
  return (
    <div className="login-page">
      <div className="login-page-logo">
        <img src={logo} alt="databridge" className="logo" />
        <span className="">Databridge</span>
      </div>
      <div className="login-page-container">
        {/* <LogoOnLogin /> */}
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
