import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { userDetails } from "../interfaces";
const initialState: userDetails = {
  email: "",
  name:""
};

const userDetailsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserDetails: (state, action: PayloadAction<userDetails>) => {
      state.email = action.payload.email;
      state.name= action.payload.name
    },
  },
});

export const { addUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
