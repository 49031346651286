import { ReactElement, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridColumnResizeParams } from "@mui/x-data-grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Loader from "../Loader/Loader";
import "./FileData.css";
import { useAppDispatch, useAppSelector } from "../../hook";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getResult } from "../../Api/getResultApi";
import {
  addDeviceDetails,
  addResult,
  addTaskDetails,
  clearResult,
} from "../../redux/taskResultSlice";
import { downloadCSV } from "../../helperFunctions";
import { IoMdDownload } from "react-icons/io";
import axios from "axios";
import toast from "react-hot-toast";
import { toastMessage } from "../../toastMessage";
import PaginateContainer from "../PaginateContainer/PaginateContainer";
import { DeviceDetailsHeader, ResultHeader } from "../../constants";

interface fileDataProps {
  resultLoading: boolean;
  setResultLoading: React.Dispatch<React.SetStateAction<boolean>>;
  resultColumnWidth: {
    field: string;
    width: number;
  }[];
  setResultColumnWidth: React.Dispatch<
    React.SetStateAction<
      {
        field: string;
        width: number;
      }[]
    >
  >;
  deviceColumnWidth: {
    field: string;
    width: number;
  }[];
  setDeviceColumnWidth: React.Dispatch<
    React.SetStateAction<
      {
        field: string;
        width: number;
      }[]
    >
  >;
}

const FileData: React.FC<fileDataProps> = ({
  resultLoading,
  setResultLoading,
  resultColumnWidth,
  setResultColumnWidth,
  deviceColumnWidth,
  setDeviceColumnWidth,
}): ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pageParams = Number.isNaN(searchParams.get("page"))
    ? 1
    : Number(searchParams.get("page"));
  const pageSizeParams = Number.isNaN(searchParams.get("pageSize"))
    ? 6
    : Number(searchParams.get("pageSize"));

  const deviceDetails = useAppSelector((state) => state.result.deviceDetails);
  const resultDetails = useAppSelector((state) => state.result.result);

  const [pageSize, setPageSize] = useState<number>(
    Math.abs(pageSizeParams) || 6
  );
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(
    Math.abs(pageParams) || 1
  );

  const { taskId } = useParams<string>();

  const theme = createTheme({
    palette: {
      mode: "light", // Ensure 'light' or 'dark' is set correctly
    },
  });

  const resultHeader: GridColDef[] = [
    {
      field: "download",
      headerName: "Download",
      width: 100,
      renderCell: (params) => {
        const handleDownload = () => {
          downloadCSV(params.row, deviceDetails[0], params.id);
        };

        return (
          <div className="row-download-button">
            <IoMdDownload
              size={25}
              color="light-blue"
              onClick={() => handleDownload()}
            />
          </div>
        );
      },
    },
    ...ResultHeader.map((column) => {
      const existingIndex = resultColumnWidth.find(
        (col) => col.field === column
      )?.width;
      return {
        field: column,
        headerName: column.toUpperCase(),
        width: existingIndex || 100,
      };
    }),
  ];

  const deviceDetailsHeader: GridColDef[] = DeviceDetailsHeader.map((key) => {
    const existingIndex = deviceColumnWidth.find(
      (col) => col.field === key
    )?.width;

    return {
      field: key,
      headerName: key.toUpperCase(),
      width: existingIndex || 104.9,
    };
  });

  const deviceWithId = deviceDetails.map((item, index) => ({
    ...item,
    id: index,
  }));

  const resultWithId = resultDetails.map((item, index) => ({
    ...item,
    id: index,
  }));

  const fetchResult = async () => {
    try {
      const response = await getResult(
        { requestedTaskId: taskId },
        Math.abs(currentPage),
        Math.abs(pageSize)
      );
      dispatch(addResult(response.result.matchingRow));
      dispatch(addDeviceDetails(response.result.advikDict));
      setTotalPages(response.totalPages);
      setCurrentPage(response.page);
      setPageSize(response.pageSize);
      taskId &&
        dispatch(
          addTaskDetails({ taskName: response.taskName, taskId: taskId })
        );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const statusCode = error.response?.status;
        toast.error(toastMessage[statusCode || 500]);
        dispatch(clearResult());
        taskId && dispatch(addTaskDetails({ taskId: taskId, taskName: "" }));
      }
    } finally {
      setResultLoading(false);
    }
  };
 
  const handleDeviceColumnResize=(e:GridColumnResizeParams)=>{
    setDeviceColumnWidth((prev)=>{   
      const existingIndex = deviceColumnWidth.findIndex((col)=>col.field===e.colDef.field);
      if(existingIndex>=0){
       const updated = [...prev];
       updated[existingIndex] = {field:e.colDef.field,width:e.width};
       return updated;
      }
      return [...prev,{field:e.colDef.field,width:e.width}]
    })
  
  }

  const handleResultColumnResize = (e: GridColumnResizeParams) => {
    setResultColumnWidth((prev) => {
      const existingIndex = prev.findIndex(
        (col) => col.field === e.colDef.field
      );
      if (existingIndex >= 0) {
        const updated = [...prev];
        updated[existingIndex] = { field: e.colDef.field, width: e.width };
        return updated;
      }
      return [...prev, { field: e.colDef.field, width: e.width }];
    });
  };

  useEffect(() => {
    navigate(`?page=${currentPage}&pageSize=${pageSize}`);
    fetchResult();
  }, [taskId, pageSize, currentPage]);

  return (
    <>
      {resultLoading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <div className="file-data-container">
          {resultDetails.length === 0 ? (
            <div className="result-message"> NO RESULT FOUND</div>
          ) : (
            <>
              <ThemeProvider theme={theme}>
                <div className="device-details-container">
                  <span>Device Details</span>
                  <DataGrid
                    className="device-details-table"
                    rows={deviceWithId}
                    columns={deviceDetailsHeader}
                    rowHeight={45}
                    columnHeaderHeight={50}
                    hideFooter
                    onColumnResize={handleDeviceColumnResize}
                    sx={{
                      ".css-1tdeh38": {
                        display: "none",
                      },
                      // minWidth:'90%'
                    }}
                  />
                </div>

                <div className="result-container">
                  <span>Result</span>
                  <DataGrid
                    className="result-table"
                    rows={resultWithId}
                    columns={resultHeader}
                    rowHeight={45}
                    columnHeaderHeight={50}
                    hideFooter
                    onColumnResize={handleResultColumnResize}
                    sx={{
                      minHeight: "328px",
                    }}
                  />

                  <PaginateContainer
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                  />
                </div>
              </ThemeProvider>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default FileData;
