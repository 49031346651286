import { ReactElement, useEffect, useState } from "react";
import { format } from "date-fns";
import "./TaskListContainer.css";
import { useDisclosure } from "@chakra-ui/react";
import DeleteTaskModal from "../modals/DeleteTaskModal/DeleteTaskModal";
import AddTaskModal from "../modals/AddTaskModal/AddTaskModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hook";
import toast from "react-hot-toast";
import axios from "axios";
import { toastMessage } from "../../toastMessage";
import { addTaskList, changeStatus } from "../../redux/taskListSlice";
import PaginateContainer from "../PaginateContainer/PaginateContainer";
import { fetchAllTask } from "../../Api/fetchAllTaskApi";
import Loader from "../Loader/Loader";
import 'tippy.js/dist/tippy.css';
import TasklistTable from "../TasklistTable/TasklistTable";

const TaskListContainer: React.FC = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const pageParams = Number.isNaN(searchParams.get("page"))? 1:Number(searchParams.get("page"));
  const pageSizeParams = Number.isNaN(searchParams.get("pageSize"))?10:Number(searchParams.get("pageSize"));

  const [loading, setLoading] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(Math.abs(pageSizeParams)||10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(Math.abs(pageParams)||1);


  const taskList = useAppSelector((state) => state.taskList.taskList);

  const [deleteTaskDetails, setDeleteTaskDetails] = useState({
    taskId: "",
    taskName: "",
  });

  const dispatch = useAppDispatch();
  const { isOpen:isDeleteOpen, onClose:onDeleteClose, onOpen:onDeleteOpen } = useDisclosure();
  const {
    isOpen: addTaskIsOpen,
    onClose: addTaskOnClose,
    onOpen: addTaskOnOpen,
  } = useDisclosure();
  const navigate = useNavigate();

  const handleViewClick = async (taskId: string, taskName: string) => {
    navigate(`result/${taskId}?page=1&pageSize=6`);
  };



  const fetchTask = async () => {
    try {
      setLoading(true);
      const response = await fetchAllTask(
        pageSize,
        currentPage
      );
      if (response) {
        if (response.totalTasks === 0) {
          dispatch(addTaskList({ taskList: [], totalTasks: 0 }));
          toast.error(response.message);
          return;
        }
        const tasks = response.tasks.map(
          (item: {
            name: string;
            details: string;
            _id: string;
            status: string;
            createdAt: string;
            message:string
          }) => ({
            name: item?.name,
            details: item?.details,
            id: item?._id,
            status: item?.status === "errored" ? "failed" : item?.status,
            date: format(item?.createdAt, "dd-MM-yyyy"),
            message:item?.message||"Something went wrong",
          })
        );
        dispatch(
          addTaskList({ taskList: tasks, totalTasks: response.totalTasks })
        );
        setTotalPages(response.totalPages);
        setCurrentPage(response.page);
        setPageSize(response.pageSize);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const statusCode = error?.response?.status;
        toast.error(toastMessage[statusCode || 500]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    navigate(`?page=${currentPage}&pageSize=${pageSize}`);
    fetchTask();
  }, [currentPage, pageSize]);

  return (
    <>
      {loading ? (
        <div className="loader">
          <Loader height={30} width={30} />
        </div>
      ) : (
        <div className="tasklist-container">
          <div className="outer-container">
            <div className="home-tab">
              <span>Home</span>
            </div>
            <div>
              <button className="add-task-button" onClick={addTaskOnOpen}>
                Add Task
              </button>
            </div>
          </div>
          <div className="table-container">

            <TasklistTable handleViewClick={handleViewClick} onOpen={onDeleteOpen} setDeleteTaskDetails={setDeleteTaskDetails} pageSize={pageSize} currentPage={currentPage}/>
            {/* <table className="tasklist-table">
              <colgroup>
                <col className="col-sNo" />
                <col className="col-date" />
                <col className="col-name" />
                <col className="col-details" />
                <col className="col-status" />
                <col className="col-view" />
              </colgroup>
              <thead className="tasklist-table-field">
                <tr className="header-row">
                  <th>S.no</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Details</th>
                  <th className="task-status-header">
                    <span>Status</span>
                    <button
                      className="mini-button"
                      onClick={() => handleGetStatus()}
                      disabled={statusLoading}
                    >
                      <IoMdRefreshCircle />
                    </button>
                  </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {taskList.map((task, index) => (
                  <tr className="task " key={task.id}>
                    <td style={{ margin: "10px" }}>
                      {(currentPage - 1) * pageSize + index + 1}
                    </td>
                    <td>{task.date}</td>
                    <td className="task-name">{task.name}</td>
                    <td className="task-details">{task.details}</td>
                    <td >
                      <Skeleton height="8" width="18" isLoaded={!statusLoading}>
                        <Tippy content={task.message} placement={"bottom"}  theme="custom"   >
                        <span
                          className={`task-status-${
                            task.status.charAt(0).toUpperCase() +
                            task.status.slice(1).toLowerCase()
                          }`}
                        >
                          {TaskStatus.includes(task.status)
                            ? task.status.charAt(0).toUpperCase() +
                              task.status.slice(1).toLowerCase()
                            : "NA"}
                        </span>
                        </Tippy>
                      </Skeleton>
                    </td>
                    <td>
                      <div className="action-div">
                        <button
                          className="task-view-button"
                          onClick={() => handleViewClick(task.id, task.name)}
                          disabled={task?.status !== "complete"}
                        >
                          View
                        </button>

                        <button
                          className="task-delete-button"
                          disabled={
                            !(
                              task?.status === "complete" ||
                              task?.status === "failed"
                            )
                          }
                          onClick={() => {
                            onOpen();
                            setDeleteTaskDetails({
                              taskId: task.id,
                              taskName: task.name,
                            });
                          }}
                        >
                          <FaRegTrashAlt />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
            <PaginateContainer
              setPageSize={setPageSize}
              pageSize={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
          <DeleteTaskModal
            isOpen={isDeleteOpen}
            onClose={onDeleteClose}
            taskDetails={deleteTaskDetails}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            fetchTask={fetchTask}
          />
          <AddTaskModal
            isOpen={addTaskIsOpen}
            onClose={addTaskOnClose}
            fetchTask={fetchTask}
            pageSize={pageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </>
  );
};

export default TaskListContainer;
