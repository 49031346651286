import { ReactElement, } from "react";
import "./PaginateContainer.css"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";


interface PaginateContainerProps{
  pageSize:number,
  setPageSize:React.Dispatch<React.SetStateAction<number>>,
  currentPage:number;
  setCurrentPage:React.Dispatch<React.SetStateAction<number>>,
  totalPages:number,
}

const PaginateContainer: React.FC<PaginateContainerProps> = ({pageSize,setPageSize,currentPage,setCurrentPage,totalPages}): ReactElement => {
  

   const pageSizeOpt=[1,2,3,4,5,6,7,8,9,10];
   const handleOnChangePageSize=(e:React.ChangeEvent<HTMLSelectElement>)=>{
    const changePageSize = Number(e.currentTarget.value);
    const  changePageNo = Math.ceil(((currentPage-1)*pageSize+1)/changePageSize);
    setCurrentPage(changePageNo);
    setPageSize(changePageSize);
    
      
  }
  const handlePaginateButton =async(btnName:string)=>{
     switch (btnName) {
      case "left-push-button":
         setCurrentPage(1);
        break;
     case "left-button":
      setCurrentPage(currentPage-1);

      break;
      case "right-button":
        setCurrentPage(currentPage+1);
        break;
        case "right-push-button":
          setCurrentPage(totalPages);
          break;
        
      default:
        break;
     }
  }
  return (
    <div className="paginate-container">
      <div className="page-details">
        <span>Row Per Page :</span>
        <select name="pageSize" id="pagesize" className="pageSizeOpt"  onChange={handleOnChangePageSize} value={pageSize}>
         
         {pageSizeOpt.map((value)=><option key={value}>{value}</option>)}
        </select>
      </div>
      <div className="paginate-button">
        <button className="mini-button" onClick={()=>handlePaginateButton("left-push-button")} disabled={currentPage===1}><MdKeyboardDoubleArrowLeft size={20}/></button>
        <button className="mini-button" onClick={()=>handlePaginateButton("left-button")} disabled={currentPage===1}>
          <MdKeyboardArrowLeft size={20} />
        </button>
        <span className="currentPage">{currentPage} of {totalPages}</span>
        <button className="mini-button" onClick={()=>handlePaginateButton("right-button")} disabled={currentPage===totalPages}>
        <MdKeyboardArrowRight  size={20} />
        </button>
        <button className="mini-button" onClick={()=>handlePaginateButton("right-push-button")} disabled={currentPage===totalPages}>
        <MdKeyboardDoubleArrowRight size={20} />
        </button>
      </div>  
    </div>
  );
};
export default PaginateContainer;