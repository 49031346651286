import { ReactElement } from "react";
import "./Navbar.css";
import logo from "../../logo/data_bridge.svg";
import { useDisclosure } from "@chakra-ui/react";
import LogoutModal from "../modals/LogoutModal/LogoutModal";
import { useAppSelector } from "../../hook";
import { useNavigate } from "react-router-dom";

const Navbar: React.FC = (): ReactElement => {
   const username = useAppSelector((state) =>state.userDetails.name)
   const navigate = useNavigate();

  const handleOptClick = async (opt: string) => {
    switch (opt) {
      case "Logout":
        onOpen();
      
        break;

      default:
        break;
    }
  };

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <div className="navbar">
      <div className=" nav-brand " >
        <img className="databridge-logo" src={logo} alt="" />
        <span onClick={()=> navigate("/")} >Databridge</span>
      </div>
      <div className="nav-opt">
        <li className="options" key={"Profile"}>{username}</li>

        <li
          className="options "
          key={"Logout"}
          onClick={() => handleOptClick("Logout")}
        >
          Logout
        <LogoutModal isOpen={isOpen} onClose={onClose} />

        </li>
      </div>
    </div>
  );
};

export default Navbar;
