import { DELETE_TASK } from "../ApiUrl";
import axiosInstance from "../axiosInstance";

export const deleteTask = async (taskId: string) => {
  if (!taskId) return;

  try {
    const response = await axiosInstance.delete(DELETE_TASK, {
      data: {
        requestedTaskId: taskId,
      },
    });
    return response.data;
  } catch (error) {
      throw error;
  }
};
