import { LOGIN } from "../ApiUrl";
import cookies from "react-cookies";
import axiosInstance from "../axiosInstance";
import { formDataInterface } from "../components/LoginForm/LoginForm";

export const login = async (userData: formDataInterface) => {
    // userData.password=await bcrypt.hash(userData.password,10)
  try {
    const response = await axiosInstance.post(LOGIN, userData);
    const accessToken = response.headers["authorization"].split(" ")[1];
    cookies.save("access-token", accessToken, {
      path: "/", // The cookie is accessible across the entire site
      maxAge: 3600, // Cookie expiration time in seconds (1 hour)
      // secure: true, // Ensures the cookie is only sent over HTTPS
      sameSite: "strict", // Restricts the cookie to the same site
    }); 
    return response;
  } catch (error) {
  
    throw error;
  }
};
