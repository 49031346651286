import { Button,  } from "@chakra-ui/react";
import { ReactElement,  useState } from "react";
import "./FileDownloadContainer.css";
import toast from "react-hot-toast";
import { downloadResult } from "../../Api/downloadResultApi";
import { useAppSelector } from "../../hook";
import axios from "axios";
import { toastMessage } from "../../toastMessage";


interface fileDownloadContainerProps{
  resultLoading:boolean,
}



const FileDownloaderContainer: React.FC<fileDownloadContainerProps> = ({resultLoading}): ReactElement => {
  const taskDetails = useAppSelector((state)=> state.result.taskDetails);
  const result = useAppSelector((state) =>state.result.result);
  const [downloadLoading,setDownloadLoading] = useState<boolean>(false);


   const downloadResultCSV = async()=>{
     try {
      setDownloadLoading(true);
       const response = await downloadResult({requestedTaskId:taskDetails.taskId});
       const blob = new Blob([response],{type:"text/csv"});
       const url = URL.createObjectURL(blob);
       const a = document.createElement("a");
       a.href= url;
       a.download= `result_${taskDetails.taskName}.csv`;
       a.click();
       URL.revokeObjectURL(url);
       toast.success("Result Download Successfully")
     } catch (error) {
        if(axios.isAxiosError(error)){
          const statusCode = error.response?.status;
          toast.error(toastMessage[statusCode||500]);
        }
     }
     finally{
      setDownloadLoading(false);
     }
   }

  
  return (
    <div className="file-download-container">
   {  !resultLoading && <> <div className="result-task-name">
            Task Name -{taskDetails.taskName}
          </div>
  
      <Button className="download-button" onClick={downloadResultCSV}disabled={downloadLoading || result.length<=0}>
        Download
      </Button>
      </>
      }
    
    </div>
  );
};

export default FileDownloaderContainer;
