import { USER_DETAILS } from "../ApiUrl";
import axiosInstance from "../axiosInstance";

export const getUserDetails = async () => {
  try {
    const response = await axiosInstance.get(
       USER_DETAILS
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
