import { DOWNLOAD_RESULT } from "../ApiUrl"
import axiosInstance from "../axiosInstance"

export const downloadResult=async(data:{requestedTaskId:string|undefined})=>{
    if(!data) return ;
    try {
        const response = await axiosInstance.post(DOWNLOAD_RESULT,data)
        return response.data;
    } catch (error) {
         throw error
    }
}