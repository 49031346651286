export const  toastMessage:Record<number,string>={
    500:"Something went wrong",
    422:"Unprocessable Entity",
    403:"Invalid Credentials",
    401:"Unauthorized Access",
    400:"Bad Request"
}
// export const toastMessageDeleteTask:Record<number,string> ={
//     404:"Task may not exit",
//     500:"Something went wrong"
// }

/**
 * 
 * 404 -
 */