import { GET_RESULT } from "../ApiUrl"
import axiosInstance from "../axiosInstance"

export const getResult=async( data:{requestedTaskId:string|undefined},currentPage:number,pageSize:number)=>{
   if(!data.requestedTaskId) return;
   try {
      const response = await axiosInstance.post(GET_RESULT+"?pageSize="+pageSize+"&page="+currentPage,data);
      return response.data;
   } catch (error) {
       throw error;
   } 
}